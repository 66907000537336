.contact-widgate{
  input.send
  {
    text-decoration:none;
    font-size:20px;
    color:#00352B;
    position:relative;
    width:209px;
    height:64px;
    line-height:64px;
    border: 1px solid white;
    display: inline-block;
    text-align:center;
    margin-top:20px;
    margin-bottom:0px;
    background-color: white;
    border: 1px solid #00352B;
  }
  background-image: url("/media/default/contact.png");
  background-repeat: no-repeat;
  background-size: cover;
  //min-height: 1110px;
  font-family:'Open Sans';
  font-weight: 400;
  color: white;
  padding-bottom: 35px;
  //text-shadow: 4px 2px 10px rgba(0, 0, 0, 1);
  @media(max-width:991px){
    //min-height: 1050px;
  }
  @media(max-width:767px){
    padding-bottom: 73px;
  }
  &__title{
    text-align: center;
    font-size: 84px;
    font-weight: 700;
    margin-top: 49px;
    @media(max-width:991px){
      font-size: 61px;
      font-weight: 700;
      margin-top: 47px;
      margin-right: 17px;
    }
    @media(max-width:767px){
      font-size: 40px;
      margin-top: 38px;
      margin-right: 7px;
    }
    &--small{
      font-size: 28px;
      text-align: center;
      margin-top: 26px;
      padding-left: 4px;
      @media(max-width:991px){
        margin-top: 39px;
        padding-left: 0px;
        padding-right: 52px;
      }
      @media(max-width:767px){
        font-size: 22px;
        padding: 0 50px;
        margin-top: 28px;
        margin-right: 11px;
        line-height: 29px;
      }
    }
  }

  &__company{
    font-family: "Trajan";
    text-align: center;
    &--name{
      font-size: 37px;
      margin-top: 14px;
      letter-spacing: 0px;
      padding-left: 3px;
      @media(max-width:991px){
        margin-top: 39px;
        padding-left: 0px;
        margin-right: 22px;
      }
      @media(max-width:767px){
        margin-top: 18px;
        font-size: 34px;
        margin-right: 0px;
      }
    }
    &--description{

      font-size: 13px;
      word-spacing: 7px;
      margin-top: -7px;
      padding-left: 8px;
      @media(max-width:991px){
        padding-left: 0px;
        margin-right: 15px;
      }
      @media(max-width:767px){
        padding-left: 9px;
        margin-right: 0px;
        word-spacing: 2px;
      }
    }
  }
  &__address{
    font-size: 18px;
    text-align: center;
    @media(max-width:767px){
      font-size: 16px;
    }
    &--city{
      margin-top: 15px;
      @media(max-width:991px){
        margin-top: 14px;
        margin-right: 23px;
      }
      @media(max-width:767px){
        margin-top: 16px;
        margin-right: 5px;
      }
    }
    &--street{
      margin-top: 3px;
      @media(max-width:991px){
        margin-right: 20px;
        letter-spacing: 0.4px;
      }
      @media(max-width:767px){
        margin-right: 0px;
      }

    }
  }
  &__social-media{
    color: white;
    text-align: center;
    margin-top: 77px;
    margin-left: 10px;
    &--ico{
      display: inline;
      font-size: 30px;
      color: white;
      margin-left: 10px;
    }
    @media(max-width:991px){
      margin-top: 39px;
      margin-left: -30px;
    }
    @media(max-width:767px){
      margin-top: 49px;
      margin-left: -17px;
    }
  }
  &__line{
    margin-top: 22px;
    border: 0;
    border-top: 2px solid #E6E6E6;
    width: 1104px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width:1199px){
      width: 830px;
    }
    @media(max-width:991px){
      width: 707px;
    }
    @media(max-width:767px){
      width: 286px;
    }
  }
  &__form {
    max-width: 687px;
    margin: 0 auto;
    margin-top: 35px;
    position: relative;

    @media(max-width:991px){
      margin-left: -13px;
    }
    .form-control{
      background: transparent;
      border: 0;
      border-bottom: 1px solid #00342b;
      color: #00342b;
      padding: 5px 0 5px 0;
      margin-bottom: 20px;
      font-size: 18px;
      box-shadow: none;
      padding-left: 1px;
      margin-left: 18px;
      &--first{

        padding: 0px;
        @media(max-width:767px){
          font-size: 15px;
          padding: 0px 0;
          margin-top: -4px;
          margin-left: 12px;
        }
      }
      &--second{
        margin-top: 26px;
        padding: 0px;
        @media(max-width:767px){
          margin-top: 12px;
          padding: 0px;
          margin-left: 10px;
        }
      }
      &:focus{
        box-shadow: none;
        background: transparent;
      }
    }
    &--checkbox-wrapper{
      color: #00342b;
      margin-bottom: 10px;
      text-align: left;
      @media(max-width:991px){
        margin-left: 20px;
      }
    }
    &--required{
      @media(max-width:991px){
        margin-left: 20px;
      }
    }
    &--label{
      font-weight: 300;
      display: inline;

      &-expandable{
        display: none;
      }
      &-visible{
        display: inline!important;
      }
    }

    &--more-button{
      display: inline;
      cursor: pointer;
      font-weight: bold;
    }
  }
}