footer{
    background-color: #000;
    color: #fff;
    padding-top: 35px;
    padding-bottom: 25px;
    font-size: 13px;
    font-family:'Open Sans';
    font-weight: 400;
    border-top:0px;
    min-height:10vh;
    
    &__link{
        margin-top: 6px;
        a{
            color: white;
            &:hover{
                color: white;
            }
        }
    }
    &__left{
        margin-left: -11px;   
    }
    &__right{
        right: 0;
        position: absolute;
    }
}