.privacyPolicy
{
    margin-top:205px;
    @media (max-width: $screen-sm) {
        margin-top: 165px;
    }
}
.instagram, .facebook
{
    position: absolute;
    top:10px;
}
.facebook
{
    right:63px;
}
.instagram
{
    right:17px;
}
$grid-gutter-width:         30px !default;
// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


.pageContent {
    @extend .tinymceContent;

}
.pageMainContent
{
    .banner
    {
        margin-bottom:70px;
        img {
            @media (max-width: 992px) {
                width: 100%;
            }
        }
    }
    color: #00342b;
    font-family: "Open Sans";
    font-weight: 300;
    text-align:center;
    margin-top:205px;
    .pageContent
    {
        &.container {
            @media (min-width: $screen-sm-min) {
                width: $container-sm;
            }
            @media (min-width: $screen-md-min) {
                width: $container-md;
            }
            @media (min-width: $screen-lg-min) {
                width: $container-lg;
            }
        }
    }
    @media (max-width: $screen-sm) {
        margin-top: 165px;
    }
    .title
    {
        font-size:56px;
        margin-top:0px;
        margin-bottom:90px;
    }
    .breakcams
    {
        font-size: 30px;
        text-decoration: none;
        margin-top:25px;
    }
    .content
    {
        font-size: 17px;
    }

    a.button
    {
        margin-top:90px;
        text-decoration:none;
        font-size:20px;
        color:red;
        position:relative;
        left: calc(50% - 135px);
        transition: 1s ease;
        width:270px;
        height:55px;
        line-height:55px;
        border: 1px solid red;
        display:block;
        text-align:center;
    }
}