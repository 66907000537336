.about-company{

  font-family:'Open Sans';
  font-weight: 300;
  display:block;
  background-color:#EEEEEE;
  .about-company-wrapper
  {
      text-align:center;
      color:#00342B;
    .title
    {
      padding-top:65px;
      font-size: 56px;
    }
    .subTitle
    {
      padding-top:80px;
      font-size:38px;
    }
    .description
    {
      padding-top:70px;
      font-size:17px;
    }
    
    a.button
    {
      text-decoration:none;
      font-size:20px;
      color:red;
      position:relative;
      width:209px;
      height:64px;
      line-height:64px;
      border: 1px solid red;
      display: inline-block;
      text-align:center;
      margin-top:60px;
      margin-bottom:65px;
    }
  }
}
