.our-offer-container
{
  &.container
  {
    @media (min-width: 768px) and (max-width: 992px)
    {
      width:100%;
    }
    @media (max-width: 769px) {
      padding-left: 0;
      padding-right: 0;
      margin-right:0;
      margin-left:0;
    }
  }
  .title{
    font-size: 56px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    font-family:'Open Sans';
    font-weight: 300;
  }
  .container-image {
    font-family:'Open Sans';
    font-weight: 300;
      position: relative;
      padding:0;
      background-color:black;
        @media screen and (max-width: $screen-sm) {
          width: 100vw;
        }
      .image {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: 0.5s ease;
        backface-visibility: hidden;
        @media screen and (max-width: $screen-sm) {
          width: 100vw;
        }
      }
  
      .middle {
        transition: 1s ease;
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        a.button
        {
          text-decoration:none;
          font-size:20px;
          color:white;
          position:relative;
          bottom: 18px;
          left: 25px;
          transition: 1s ease;
          width:180px;
          height:55px;
          line-height:55px;
          border: 1px solid white;
          display:block;
          text-align:center;
        }
        .text {
          color: white;
          font-size: 16px;
          padding: 16px 32px;
        }
      }
      .text2
      {
        font-size:30px;
        color:white;
        position:absolute;
        bottom: 30px;
        left: 25px;
        transition: 1s ease;
        max-width:180px;
      }
      &:hover .image {
        opacity: 0.3;
      }
      
      &:hover .middle {
        opacity: 1;
      }
      &:hover .text2 {
        bottom:100px;
      }
  }
}