.our-offer-container
{
  .title{
    font-size: 56px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .gallery-container
  {
    .image
    {
      padding:0;
      @media screen and (min-width: $screen-sm+1) {
      max-height:460px;
      max-width:480px;
      }
    }
      @media screen and (max-width: $screen-sm) {
        width: 480px;
      }
  }
}

.carousel{
    padding-top: 180px;
    transition-duration: 1s;
    @media(max-width:991px){
            padding-top: 80px;
        }
        @media(max-width:767px){
            padding-top: 72px;
        }
    &--scroll{
        transition-duration: 1s;
    }
}

.header{
    background-color: $header-color;
    width: 100%;
    height: 180px;   
    position: fixed; 
    transition-duration: 1s;
    z-index: 100;
    @media(max-width:991px){
        height: 80px
    }
    @media(max-width:767px){
        height: 72px;
    }
    &--scroll{
        position: fixed;
        z-index: 100;
        margin-top: -120px;
        transition-duration: 1s;
    }
    &__link-home{
        color: #fff;
        &:hover{
            color: #fff;   
            text-decoration: none;
        }
    }
    &__text{
        font-family: "Trajan Pro";
        color: white;
        line-height: 27px;
        &--center{
            text-align: center;
        }
        &--restaurant-name{
            font-size: 33px;
            margin-top: 32px;
            letter-spacing: -0.4px;
            @media(max-width:991px){
                font-size: 28px;
                letter-spacing: 0.5px;
                margin-top: 24px;
            }
            @media(max-width:767px){
                font-size: 22px;
                letter-spacing: 1.1px;
                margin-top: 12px;
                margin-right: 4px;
            }
        }
        &--restaurant-description{
            font-size: 10px;
            margin-top: -2px;
            letter-spacing: 1px;
            word-spacing: 4px;
            @media(max-width:991px){
                font-size: 11px;
                margin-top: -6px;
                margin-left: 6px;
            }
            @media(max-width:767px){
                font-size: 8px;
                margin-top: -8px;
                margin-left: 6px;
                word-spacing: 1px;
            }
        }
        &--link{
            font-family: Oxygen;
            color: $header-link-color!important;
            font-size: 14px;
            letter-spacing: -0.1px;
            margin-right: 48px;
            padding-bottom: 10px!important;
            padding-left: 0px!important;
            padding-right: 0px!important;
            &:hover{
                color: #fff!important;
                border-bottom: 2px solid $header-link-color;
            }
            @media(max-width:1199px){
                margin-right: 28px;
            }
        }
    }

}
.navbar{
    margin-bottom: 0px;
    &-default{
        background-color: transparent;
        border: none;
    }
    &-nav{
        float:right;
        margin-right: -18px;
    }
}
.menu{
    margin-top: 40px;
}

.carousel-inner > .item > img, .carousel-inner > .item > a > img{
    width: 100%;
    height: 644px;
    @media(max-width:991px){
        height: 270px;
    }
    @media(max-width:767px){
        height: 175px;
    }
    
}
.carousel-caption {
    left: 0;
    right: 0;
   
}
.carousel-indicators {
    bottom: 11px;
    .active{
        width: 22px;
        height: 22px;
        background-color: $main-color;
    }
    li{
        width: 22px;
        height: 22px;
        border: 3px solid #fff;
    }
}
.slider{
    &__text{
        font-family: "Oxygen";
        text-align: left;
        font-weight: 300;
        &--title{
            position: relative;
            font-size: 37px;
            bottom: 138px;
            margin-left: 3px;
            word-spacing: 2px;
            @media(max-width:1400px){
                margin-left: 90px;
            }
            @media(max-width:991px){
                font-size: 26px;
                bottom: -4px;
                margin-left: 65px;
                word-spacing: 1px;
                letter-spacing: 0.7px;
            }
            @media(max-width:767px){
                font-size: 20px;
                bottom: -18px;
                margin-left: 46px;
                word-spacing: 0px;
                letter-spacing: 0px;
                width: 215px;
                line-height: 24px;
            }
        }
        &--content{
            font-size: 25px;
            position: relative;
            bottom: 122px;
            margin-left: 3px;
            width: 561px;
            word-spacing: 0px;
            line-height: 31px;
            @media(max-width:1400px){
                margin-left: 90px;
                
            }
            @media(max-width:991px){
                line-height: 23px;
                font-size: 19px;
                bottom: -24px;
                margin-left: 64px;
                width: 425px;
            }
            @media(max-width:767px){
                line-height: 16px;
                font-size: 13px;
                bottom: -26px;
                margin-left: 46px;
                width: 206px;
                letter-spacing: 0.3px;
            }
        }
    }
    &__arrows{
        position: absolute;
        top: 50%;
        @media(max-width:991px){
            top:40%;
            img{
                height: 53px;
                width: 41px;
            }
        }
        @media(max-width:767px){
            top:37%;
            img{
                height: 42px;
                width: 31px;
            }
        }
        &--right{
            left: auto;
            right: 0;
        }
        &--left{
            left: 0;
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }
    }
}

.strap{
	display: block;
	height:6px;
	width:33px;
	background: $HamburgerColor;
	margin-bottom:4px;
    @media(max-width:767px){
        margin-bottom: 5px;
        height: 5px;
        width:34px;
    }
}
.hamburger-hidden{
	@media (min-width:992px){
		display: none;
	}
}
.hamburger, .cross{
	background:none;
    position: fixed;
	top: 20px;
    right: 20px;
	padding:5px 15px 0px 15px;
	border:0;
	outline:none;
	z-index:10000000000000;
    @media(max-width:767px){           
        top: 17px;
        right: 5px;
    }
}
.active{
    
	color: #fff!important;
    a{
        color: #fff!important;
        background-color: transparent!important;
    }

}
.menuHamburger{
	z-index:1000000; 
	font-weight:300; 
	font-size:13px; 
    margin-top: 3px;
	width:100%; 
	background: black;  
	position: relative;  
	display: none;
    &--scroll{
        margin-top: 48px;
        overflow: auto;
        max-height: 100vh;
        @media(max-width:767px){ 
            margin-top: 62px;
        }
    }
  
}
.menuHamburger ul {
	margin: 0; 
	padding: 0; 
	list-style-type: none; 
	list-style-image: none;
}
.menuHamburger li {
	display: block;   
	text-align: left;
    padding: 21px 0 21px 50px;
	border-bottom: 1px solid #ffffff;
}
.menuHamburger li:hover{

	border-bottom: 1px solid #ffffff;
}
.menuHamburger ul li a { 
	text-decoration:none;  
	margin: 0px; 
	color:#545454;
    font-size: 16px;
}
.menuHamburger ul li a:hover {  
	color: #249D9A; 
	text-decoration:none;
}
.menuHamburger a{
	text-decoration:none; 
	color:#666;
}
.menuHamburger a:hover{
	text-decoration:none; 
	color:#666;
}