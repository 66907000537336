
#bs-example-navbar-collapse-1 > ul > li > a
{
  @media (min-width: $screen-md) {
    &:hover, &:focus {
      text-decoration: none;
      background-color: inherit !important;
      color: #C82F2B !important;
    }
  }
  &:hover, &:focus {
    text-decoration: none;
    background-color: inherit ;
    color: #00352B ;
  }
}
#menu > nav > div > div.navbar-header > button {
  //margin-bottom: 10px;
  @media (max-width: $screen-md) {
    margin-left: 0;
    height: 25px;
    width: 41px;
    span {
      display: block;
      position: absolute;
      height: 9px;
      width: 100%;
      background: #d3531a;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    span:nth-child(2) {
      top: 0px;
    }

    span:nth-child(3) {
      top: 5px;
    }

    span:nth-child(4) {
      top: 14px;
    }
    &.open {

      span:nth-child(2) {
        top: 5px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      span:nth-child(3) {
        opacity: 0;
        left: -60px;
      }

      &.open span:nth-child(4) {
        top: 1px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}
#bs-example-navbar-collapse-1 > ul > li
{

  @media (max-width: $screen-md)
  {
    .menu-item
    {
      border-bottom:none;
    }
    padding-left: 20vw;
    padding-right: 20vw;
    float:none;
    border-bottom: 1px solid white;
  }
}
#bs-example-navbar-collapse-1 > ul > li.dropdown.open > ul
{
  @media (max-width: 992px) {
    &::before {
      content:none;
    }
  }
}
@media (max-width: 992px) {
  // Dropdowns get custom display when collapsed
  .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    > li > a,
    .dropdown-header {
      padding: 5px 15px 5px 25px;
    }
    > li > a {
      &:hover,
      &:focus {
        background-image: none;
      }
    }
  }
}
.menu
{
  font-family:'Open Sans';
  font-weight: 400;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  z-index: 10;
  .container{
    @media (max-width: 992px) {
      width: 100vw;
      padding:0;
      margin-left: -1px;
    }
  }
  #bs-example-navbar-collapse-1 > ul > li > a
  {
    @media (max-width: 992px) {
      &:hover,&:focus {
        background-color: initial;
      }
    }
  }
  #bs-example-navbar-collapse-1 > ul > li.dropdown.open > a
  {
    background-color:initial !important;
  }
  #menu > nav > div > div.navbar-header > button
  {
    @media (max-width: 992px) {
      margin-right:0;
    }
  }
  &.shrink {
    .container
    {
      margin-bottom: -20px;
      transition: margin-bottom 0.5s ease;
    }
  }
  .container
  {
    margin-bottom: -40px;
    transition: margin-bottom 0.5s ease;
  }
  &.transparent
  {
    background-color:rgba(255,255,255,0.84);

  }
  transition: height 0.5s ease;
  &.open
  {
    @media (max-width: $screen-md) {
      height: 145px;
    }
    &.shrink
    {
      height:115px;
    }
    height:185px;
    margin-top: 0px;

  }
  .logo
  {
    transition: margin-bottom 0.5s ease;
    @media (max-width: $screen-md) {
      margin-bottom: 0;
    }
    &.shrink
    {

      margin-bottom: 0;
      img{
        height:50px;
      }
    }
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
    img{
      transition: height 0.5s ease;
      height:100px;
    }
  }
  li.dropdown
  {
    .menu-item
    {
      border-bottom: none;
    }
  }
  .menu-item
  {
    color:#00352B;
    @media (max-width: $screen-md) {
      color:white;
      font-family:'Open Sans';
      font-weight: 400;
      font-size:14px;
    }
    @media (min-width: $screen-md) {
      border-bottom: none;
    }
    border-bottom: 1px solid white;
    font-size: 14px;
    font-weight:bold;
    span.plus
    {
        font-size: 16px;
    }
  }
  .container-fluid > .navbar-collapse {
    @media (max-width: $screen-md)
    {
      padding-left: 0;
      padding-right: 0;
      background-color:#C82F2B;
    }
  }
  #bs-example-navbar-collapse-1 > ul > li.dropdown.open > a
  {
    border-bottom: 1px solid white;
  }
  #bs-example-navbar-collapse-1 > ul
  {
    @media (min-width: $screen-md) {
      .navbar-nav {
        float: left;
        margin: 0;
      }
    }
  }
  #menu > nav > div
  {
    text-align:center;
    >div.navbar-header {
      display: inline-block;
      text-align: center;
    }
  }
  #menu > nav > div > div.navbar-header > button
  {
    .icon-bar
    {
      background-color:#C82F2B;
      width:40px;
      height:5px;
    }
  }
  @media (min-width: $screen-md) {
    .navbar-nav {
      float: left;
      margin: 0;
    }
  }
  .navbar-toggle
  {
    padding-bottom:7px;
    margin-bottom:0;
  }
  #menu
  {
    @media (min-width: $screen-md) {
      .collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
      }
    }
    @media (max-width: $screen-md) {
      .collapse {
        //display: none !important;
      }
    }
    nav
    {
      .navbar-collapse
      {
        text-align: center;
        @media (max-width: $screen-md)
        {
          height:calc(100vh - 165px);
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        ul.nav.navbar-nav
        {
          clear: both;
          display: inline-block;
          float: none;
        }
      }
    }

  }
}
#menu > nav > div > div.navbar-header
{
  @media (min-width: 768px) {
    float: none !important;
  }
  >button {
    @media (max-width: $screen-md) {
      display: block;
    }
    @media (min-width: $screen-md) {
      display: none;
    }
    margin-top:40px;
  }
}