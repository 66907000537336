.contact-block {
    background-color: $default-color;
    margin: 20px 0;
    text-align: center;
    color: white;
    &__left-container {
        padding-bottom: 20px;
    }
    &__title {
        font-size: 30px;
        text-transform: uppercase;
        padding-left: 4%;
        margin-top: 50px;
        @media only screen and (max-width: 1199px) {
            margin-top: 25px;
            font-size: 25px;
        }
    }
    &__text {
        font-size: 18px;
        @media only screen and (max-width: 1199px) {
            font-size: 15px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 13px;
        }
        &--name {
            padding-left: 7%;
            padding-bottom: 0;
            margin-bottom: 12px;
        }
    }
    &__link {
        color: white;
        &:hover {
            color: white;
        }
    }
    &__image {
        margin: 15px 0px;
        max-width: 100%;
        @media only screen and (max-width: 767px) {
            margin: 15px auto;
        }
    }
}
#contactForm{
    *{
        text-shadow: none;
    }
}