.gallery{
    min-height: 64vh;
    background-color: white;
    &__title{
         text-transform: uppercase;
        font-size: 90px;
        margin-top: 43px;
        font-family: "Trajan Pro";
        text-align: center;
        opacity: 0.2;
        font-weight: 900;
        padding-right: 41px;
        letter-spacing: 2.5px;
        @media(max-width:767px){
            font-size: 56px;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
    }
    &__line{
        width: 78%;
        background-color: #DBDBDB;
        height: 1px;
        margin: 0 auto;
        margin-bottom: 21px;
        margin-top: 5px;
        position: relative;
    }
    &__image{
        color: #fff;
        font-size: 0.0001em;
        &--img{
            width: 24%;
            margin: 10px 0.5%;
            border-radius: 5px;
            @media(max-width:1024px){
                width: 32%;
            }
            @media(max-width:767px){
                width: 49%;
            }
            @media(max-width:500px){
                width: 100%;
            }
        }
    }
}