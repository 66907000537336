.our-realizations-container-wrapper {

  .owl-nav
  {
    position: absolute;
    top:calc(50% - 27px);
    width: 100%;
    [class*='owl-']
    {
      background: none !important;
      &:hover
      {
        background: none !important;
      }
    }
    .owl-prev
    {
      float: left;
    }
    .owl-next
    {
      float: right;
    }

  }

  @media (min-width: $screen-lg) {
    margin-top: 80px;
  }
  .our-realizations-container {

    font-family: 'Open Sans';
    font-weight: 300;
    background-image: url('/imagefly/w640-q60-c/css/frontend/media/images/realizations-background.png');
    background-size: cover;
    padding-bottom: 60px;
    display: inline-block;
    width: 100%;

    .description {
      text-align: center;
      color: white;
      font-size: 17px;
      padding-bottom: 75px;
    }
    .title {
      font-size: 56px;
      text-align: center;
      padding-top: 60px;
      padding-bottom: 60px;
      color: white;
    }
    .container-image {
      position: relative;
      padding: 0;
      background-color: black;
      .image {
        opacity: 1;
        display: block;
        width: 100%;
        height: auto;
        transition: 1s ease;
        backface-visibility: hidden;
      }

      .middle {
        transition: 1s ease;
        opacity: 0;
        position: absolute;
        bottom: 12%;
        left: 0;
        width: 100%;
        a.button {
          text-decoration: none;
          font-size: 20px;
          color: white;
          position: relative;
          left: 21%;
          transition: 1s ease;
          width: 59%;
          height: 16%;
          line-height: 55px;
          border: 1px solid white;
          display: block;
          text-align: center;
        }
        .text {
          color: white;
          font-size: 16px;
          padding: 16px 32px;
        }
      }
      .text2 {
        font-size: 30px;
        color: white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 1s ease;
        // max-width:180px;
        opacity: 0;
        width: 100%;
        text-align: center;
        display: block;
      }
      &:hover .image {
        opacity: 0.3;
      }

      &:hover .middle {
        opacity: 1;
      }
      &:hover .text2 {
        bottom: 100px;
        opacity: 1;
      }
    }
  }
}