.contact-us
{
  font-family:'Open Sans';
  font-weight: 300;
  text-align:center;
  background-image:url('/imagefly/w1920-q60/css/frontend/media/images/contact-us-background.png');
  background-size:cover;
  margin-top:100px;
  .contact-us-wrapper
  {
    .title
    {
      font-size:53px;
      padding-top:145px;
      color:white;
    }
    .description
    {
      font-size:18px;
      padding-top:45px;
      color:white;
    }
    .phone,.email
    {
      font-family:'Open Sans';
      a
      {
        color:#E17A78;
        margin-left:20px;
        @media screen and (max-width: $screen-sm) {
          margin-left:0px;
          display:inline-block;
          width:100%;
          margin-top:10px;
          font-weight:bold;
        }
      }
      @media screen and (min-width: $screen-sm) {
        font-size:34px;
      }
    }
    .phone
    {
      font-size:36px;
      padding-top:70px;
      font-weight:bold;
    }
    .email
    {
      font-size:20px;
      padding-top:30px;
      font-weight:bold;
    }
    
    a.button
    {
      text-decoration:none;
      font-size:20px;
      color:white;
      position:relative;
      width:209px;
      height:64px;
      line-height:64px;
      border: 1px solid white;
      display: inline-block;
      text-align:center;
      margin-top:80px;
      margin-bottom:100px;
    }
  }
}
